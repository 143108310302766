<template>
  <b-card-code
    no-body
    title="Resumo de processos"
  >
    <b-table
      responsive
      :items="processos"
      :fields="fields"
    >
      <template #cell(dataHoraAbertura)="data">

        {{ ('0' + new Date(data.value).getDate()).slice(-2) + '/' + ('0' + (new Date(data.value).getMonth()+1)).slice(-2) + '/' + new Date(data.value).getFullYear() }}

      </template>
      <template #cell(descricaoEstado)="data">
        <b-badge :variant="status[1][data.value]">
          {{ data.value }}
        </b-badge>
      </template>
      <template #cell(opcoes)="data">

        <div class="text-nowrap">
          <feather-icon
            :id="`invoice-row-${data.item.id}-edit-icon`"
            icon="EditIcon"
            size="16"
            class="mx-1"
            @click="$router.push({ name: 'editar-processo-id', params: { id: data.item.id } })"
          />
          <b-tooltip
            title="Editar Processo"
            :target="`invoice-row-${data.item.id}-edit-icon`"
          />
          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="$router.push({ name: 'detalhe-processo', params: { id: data.item.id } })"
          />
          <b-tooltip
            title="Visualizar Processo"
            :target="`invoice-row-${data.item.id}-preview-icon`"
          />
        </div>
      </template>
    </b-table>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BBadge, BTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BCardCode,
    BTable,
    BBadge,
    BTooltip,
  },
  props: {
    processos: Array,

  },
  data() {
    return {
      fields: [
        {
          key: 'processo', label: 'Processo', sortable: true,
        },
        { key: 'dataHoraAbertura', label: 'Data', sortable: true },

        { key: 'nomeTecnico', label: 'Técnico', sortable: true },
        { key: 'descricaoEstado', label: 'Estado', sortable: true },
        { key: 'descricaoProb', label: 'Descrição', sortable: true },
        { key: 'opcoes', label: '...' },
      ],

      status: [{
        FATURADO: 'FATURADO', CANCELADO: 'CANCELADO', SUSPENSO: 'SUSPENSO', TERMINADO: 'TERMINADO', INICIADO: 'INICIADO', PENDENTE: 'PENDENTE',
      },
      {
        INICIADO: 'light-primary', FATURADO: 'light-info', TERMINADO: 'light-danger', SUSPENSO: 'light-secundary', CANCELADO: 'light-warning', PENDENTE: 'light-success',
      }],
    }
  },
}
</script>

<template>
  <div>

    <template v-if="contrato">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <ContratoInformacao :contrato="contrato" />
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <ContratoPlano :contrato-atual="contratoAtual" />
        </b-col>
      </b-row>

      <b-row>

        <b-col
          cols="12"
          lg="12"
        >
          <ContratoHistorico :contratos="contratos" />
        </b-col>
        <b-col
          cols="12"
          lg="12"
        >
          <ContratoResumo :processos="processos" />
        </b-col>
      </b-row>

    </template>

  </div>
</template>

<script>
import router from '@/router'
import {
  BRow, BCol,
} from 'bootstrap-vue'
import axios from 'axios'
import ContratoInformacao from './ContratoInformacao.vue'
import ContratoPlano from './ContratoPlano.vue'
import ContratoResumo from './ContratoResumo.vue'
import ContratoHistorico from './ContratoHistorico.vue'

export default {
  components: {
    BRow,
    BCol,
    // Local Components
    ContratoInformacao,
    ContratoPlano,
    ContratoResumo,
    ContratoHistorico,

  },
  data() {
    return {
      contrato: {},
      contratoAtual: {},
      contratos: [],
      processos: [],
    }
  },
  async created() {
    await this.open()
    this.$root.$on('reload-contrato', id => {
      this.beforeRouteUpdate()
    })
  },

  methods: {
    async open() {
      try {
        await this.getContrato()
        await this.getContratoAtual()
        await this.getProcessosContrato()
      } catch (error) {
      // Trate o erro aqui ou lance-o novamente para cima, se necessário.
      }
    },
    async beforeRouteUpdate() {
      await this.getContrato()
      await this.getContratoAtual()
      await this.getProcessosContrato()
    },

    async getContrato() {
      const contratoId = this.$route.params.id // Obtenha o valor do parâmetro "id" da rota atual
      const apiUrl = `/api/v1/contratos/${contratoId}/complete`

      try {
        const res = await axios.get(apiUrl)
        this.contrato = res.data
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.contrato = undefined
        }
      }
    },
    async getContratoAtual() {
      try {
        const res = await axios.get(`/api/v1/contratos/entidade/${this.contrato.entidade}/complete`)
        this.contratos = res.data
        for (let i = 0; i < res.data.length; i += 1) {
          if (res.data[i].descricaoEstado === 'ATIVO') {
            this.contratoAtual = res.data[i]
            break // Terminar o loop assim que o contrato atual for encontrado
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.contratoAtual = undefined
        }
      }
    },
    async getProcessosContrato() {
      const contratoId = this.$route.params.id // Obtenha o valor do parâmetro "id" da rota atual
      const apiUrl = `/api/v1/processos/contrato/${contratoId}/complete`

      try {
        const res = await axios.get(apiUrl)
        this.processos = res.data
      } catch (error) {
        if (error.response && error.response.status === 404) {
          this.processos = undefined
        }
      }
    },
  },
}
</script>

<style>

</style>

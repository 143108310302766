<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">

          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                Contrato:  {{ contrato.referencia }}
              </h4>
              <span class="card-text">{{ contrato.descricao }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'editar-contrato', params: { id: contrato.id } }"
                variant="primary"
              >
                Edit
              </b-button>
              <b-button
                variant="outline-danger"
                class="ml-1"
              >
                Delete
              </b-button>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center mt-2 align-self-center ">

          <h4 class="align-self-center my-auto">
            Resumo de Horas
          </h4>
        </div>
        <!-- User Stats -->
        <div class="d-flex align-items-center mt-2 align-self-center">
          <div class="d-flex align-items-center mr-2">
            <div class="ml-1 textoCentrado">
              <h5 class="mb-0">
                {{ contrato.horasTotais }}
              </h5>
              <small>Horas Totais</small>
            </div>
          </div>

          <div class="d-flex align-items-center mr-2">
            <div class="ml-1 textoCentrado">
              <h5 class="mb-0">
                {{ contrato.horasGastas }}
              </h5>
              <small>Horas Gastas</small>
            </div>
          </div>
          <div class="d-flex align-items-center mr-2 ">
            <div class="ml-1 textoCentrado">
              <h5 class="mb-0">
                {{ contrato.horasDisponiveis }}
              </h5>
              <small>Horas Dísponiveis</small>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Cliente</span>
            </th>
            <td class="pb-50">
              {{ contrato.codigoEntidade }} - {{ contrato.nomeEntidade }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Estado</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ contrato.descricaoEstado }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="StarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Data Abertura</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ new Date(contrato.data).toLocaleString('pt-PT') }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="DollarSignIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Valor</span>
            </th>
            <td class="pb-50">
              {{ contrato.valorContrato }} €
            </td>
          </tr>

        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,
  },
  props: {
    contrato: {
      type: Object,
      required: true,
    },
  },
  setup() {

  },
}
</script>

<style>
.textoCentrado {
    text-align: center;
  }
</style>

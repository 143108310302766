<template>
  <b-card-code
    no-body
    title="Histórico de Contratos"
  >
    <b-table
      responsive
      :items="contratos"
      :fields="fields"
    >
      <template #cell(data)="data">

        {{ ('0' + new Date(data.value).getDate()).slice(-2) + '/' + ('0' + (new Date(data.value).getMonth()+1)).slice(-2) + '/' + new Date(data.value).getFullYear() }}

      </template>
      <template #cell(descricaoEstado)="data">
        <b-badge :variant="status[1][data.value]">
          {{ data.value }}
        </b-badge>
      </template>
      <template #cell(opcoes)="data">

        <div class="text-nowrap">
          <feather-icon
            :id="`invoice-row-${data.item.id}-edit-icon`"
            icon="EditIcon"
            size="16"
            class="mx-1"
            @click="$router.push({ name: 'editar-contrato-id', params: { id: data.item.id } })"
          />
          <b-tooltip
            title="Editar Contrato"
            :target="`invoice-row-${data.item.id}-edit-icon`"
          />
          <feather-icon
            :id="`invoice-row-${data.item.id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1"
            @click="navigateAndReload(data.item.id)"
          />

          <b-tooltip
            title="Detalhe Contrato "
            :target="`invoice-row-${data.item.id}-preview-icon`"
          />
        </div>
      </template>
    </b-table>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BTable, BBadge, BTooltip } from 'bootstrap-vue'

export default {
  components: {
    BCardCode,
    BTable,
    BBadge,
    BTooltip,
  },
  props: {
    contratos: Array,

  },
  data() {
    return {
      fields: [
        {
          key: 'referencia', label: 'Referência', sortable: true,
        },
        { key: 'data', label: 'Data', sortable: true },
        { key: 'horasTotais', label: 'Totais', sortable: true },
        { key: 'horasGastas', label: 'Gastas', sortable: true },
        { key: 'horasDisponiveis', label: 'Saldo', sortable: true },
        { key: 'descricaoEstado', label: 'Estado', sortable: true },
        { key: 'opcoes', label: '...' },
      ],
      status: [{
        ATIVO: 'ATIVO', CANCELADO: 'CANCELADO', SUSPENSO: 'SUSPENSO', TERMINADO: 'TERMINADO', INICIADO: 'INICIADO',
      },
      {
        INICIADO: 'light-primary', ATIVO: 'light-success', TERMINADO: 'light-danger', SUSPENSO: 'light-warning', CANCELADO: 'light-info',
      }],
    }
  },
  methods: {

    navigateAndReload(id) {
      this.$router.push({ name: 'detalhe-contrato-id', params: { id } });

      // Emita o evento personalizado para notificar o componente detalhe.vue
      this.$root.$emit('reload-contrato', id);
    },
  },

}
</script>
